a {
  color: inherit;
  cursor: pointer; // for anchors with no href
  text-decoration: none;

  &.current-page {
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

@mixin hover {
  &:hover {
    .no-touchevents &,
    .no-js & {
      @content;
    }
  }

  &.hover {
    @content;
  }
}
