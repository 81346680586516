.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.page {
  opacity: 1;
  float: left;
  grid-column: 1;
  grid-row: 1;

  &.transition-enter {
    opacity: 0;
  }

  &.transition-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
  }

  &.transition-exit {
    opacity: 1;
  }

  &.transition-exit-active {
    opacity: 0;
    transition: opacity 400ms ease-out;
  }

  &.transition-exit-done {
    opacity: 0;
  }

  &.outgoing-page {
    position: relative;
  }

  &.homepage {
    margin-top: 0;
  }

  &.onehundred__hidden {
    opacity: 0;
  }

  &.onehundred-index {
    &.transition-enter {
      opacity: 1;

      .page__main {
        opacity: 0;
      }
    }

    &.transition-enter-active {
      .page__main {
        opacity: 1;
        transition: opacity 600ms;
      }
    }
  }

  &.onehundred-index.swipe-enter {
    transform: translateY(0);

    .page__main {
      transform: translateY(100vh);
    }
  }
  &.onehundred-index.swipe-enter-active {
    .page__main {
      transform: translateY(0);
      transition: transform 600ms 200ms ease-out;
    }
  }

  &.onehundred-detail.swipe-enter {
    opacity: 0;
  }
  &.onehundred-detail.swipe-enter-active {
    opacity: 1;
    transition: opacity 600ms ease;
  }
}

.secondary-nav {
  transform: translateY(-103%);
  transition: transform 800ms;
  &.reveal {
    transform: translateY(0);
  }

  &.reveal-enter {
    transform: translateY(-103%);
  }
  &.reveal-enter-active {
    transform: translateY(-0%);
    transition: transform 800ms;
  }
  &.reveal-slow-enter {
    transform: translateY(-103%);
    transition: transform 800ms;
  }
  &.reveal-slow-enter-active {
    transform: translateY(0);
    transition: transform 800ms;
  }

  &.reveal-exit {
    transform: translateY(-0%);
  }
  &.reveal-exit-active {
    transform: translateY(-103%);
    transition: transform 800ms;
  }
  &.reveal-exit-done {
    transform: translateY(-103%);
  }
  &.reveal-slow-exit {
    transform: translateY(0);
  }
  &.reveal-slow-exit-active {
    transform: translateY(-103%);
    transition: transform 800ms;
  }

  .secondary-nav__list {
    float: left;
    grid-column: 1;
    grid-row: 1;

    &.outgoing-nav {
      position: relative;
      margin-top: 0;
    }
  }

  &.do-not-show {
    transform: translateY(-103%);
  }
}

.homepage__header {
  &.home-reveal {
    transform: translateY(0);
    max-height: 100%;
    overflow: hidden;
  }

  &.home-reveal-exit {
    max-height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  &.home-reveal-exit-active {
    max-height: 0%;
    overflow: hidden;
    transition: all 1500ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &.home-reveal-exit-done {
    max-height: 0%;
    overflow: hidden;
  }

  &.home-reveal-enter {
    transform: translateY(-100%);
  }

  &.home-reveal-enter-active {
    transform: translateY(0);
    transition: transform 800ms;
  }
}

.same-section {
  .outgoing-nav + .incoming-nav {
    a {
      opacity: 0;
    }
  }
}

.onehundred-nav {
  .sticky-footer {
    &__reveal {
      height: 100vh;
    }
  }

  &.swipedown {
    z-index: 2;
    &::before {
      content: ('');
      height: 100vh;
      background: white;
      position: absolute;
      top: -100vh;
      left: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &.swipe-exit {
    transform: translateY(0);
    .sticky-footer__reveal {
      height: 100vh;
    }
  }

  &.swipe-exit-active,
  &.swipe-exit-done {
    transition: transform 800ms;
    .sticky-footer__reveal {
      height: 100vh;
      transform: translateY(0);
      transition: transform 800ms;
    }
  }
}

.sticky-footer {
  &.swipe-enter {
    transform: translateY(0);

    .sticky-footer__reveal {
      opacity: 1;
    }
  }

  &.swipe-enter-active,
  &.swipe-enter-done {
    transition: transform 1200ms;
    min-height: 100vh;
    .sticky-footer__reveal {
      transform: translateY(0);
      transition: transform 1200ms;
    }
  }

  &.onehundred-detail__transition {
    &.swipe-enter {
      .sticky-footer__reveal {
        opacity: 1;
      }
    }

    &.swipe-enter-active {
      .sticky-footer__reveal {
        opacity: 0;
        transition: opacity 1200ms cubic-bezier(0, 0.67, 0.51, 1);
      }
    }
  }
}

body {
  &.fadeout-mobile-nav-exit {
    .mobile-nav,
    .work__list {
      opacity: 1;
      display: flex;
    }
  }
  &.fadeout-mobile-nav-exit-active {
    .mobile-nav,
    .work__list {
      display: flex;
      opacity: 0;
      transition: opacity 400ms;
    }
  }
}
